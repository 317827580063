/* === Tailwind === */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* === Rest of app === */

@layer utilities {
  * {
    /* outline: 1px dashed blue; */
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'roboto';
  }

  h2 {
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .navbar {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: rgba(249, 250, 251, 0.8);
  }

  .turn-white {
    -webkit-filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(108%) contrast(101%);
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(108%) contrast(101%);
  }

  .header-projects {
    background-color: #FB408F;
    background-image: linear-gradient(45deg, #FB408F, #FAE937);
  }

  .header-blog-posts {
    background-color: #FE5A59;
    background-image: linear-gradient(45deg, #FE5A59, #8205FF);
  }

  .header-about-me {
    background-color: #08F1FF;
    background-image: linear-gradient(45deg, #08F1FF, #FF5EF9);
  }

  .header-contact {
    background-color: #F9131E;
    background-image: linear-gradient(45deg, #F9131E, #B00EEB);
  }

  .icon-link {
    -webkit-filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(108%) contrast(101%);
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(90deg) brightness(108%) contrast(101%);
  }

  .icon-link-dark {
    -webkit-filter: invert(23%) sepia(7%) saturate(1340%) hue-rotate(181deg) brightness(100%) contrast(88%);
    filter: invert(23%) sepia(7%) saturate(1340%) hue-rotate(181deg) brightness(100%) contrast(88%);
  }

  .icon-link:hover,
  .icon-link:active {
    -webkit-filter: invert(83%) sepia(3%) saturate(165%) hue-rotate(174deg) brightness(102%) contrast(96%);
    filter: invert(83%) sepia(3%) saturate(165%) hue-rotate(174deg) brightness(102%) contrast(96%);
  }

  .icon-link-dark:hover,
  .icon-link-dark:active {
    -webkit-filter: invert(15%) sepia(4%) saturate(2519%) hue-rotate(184deg) brightness(99%) contrast(92%);
    filter: invert(15%) sepia(4%) saturate(2519%) hue-rotate(184deg) brightness(99%) contrast(92%);
  }

  /* === Hero animations === */

  .name-gradient {
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-color: #FE5A59;
    background-image: linear-gradient(45deg, #1f2937 40%, #FE5A59 50%, #8205FF 75%, #1f2937 100%);
    -webkit-animation: gradient 4s ease both;
    animation: gradient 4s ease both;
  }

  .first-word-gradient {
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-color: #FB408F;
    background-image: linear-gradient(45deg, #1f2937 40%, #FB408F 60%, #FAE937 75%, #1f2937 100%);
    -webkit-animation: gradient 4s ease both;
    animation: gradient 4s ease both;
  }

  .second-word-gradient {
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    background-color: #F9131E;
    background-image: linear-gradient(45deg, #1f2937 40%, #F9131E 50%, #B00EEB 75%, #1f2937 100%);
    -webkit-animation: gradient 4s ease both;
    animation: gradient 4s ease both;
  }

  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

}